import { graphql, Link } from 'gatsby';
import React from 'react';

import { Helmet } from 'react-helmet';

import { css } from '@emotion/core';
import styled from '@emotion/styled';

import SiteNav from '../components/header/SiteNav';
import { PostCard } from '../components/PostCard';
import { Wrapper } from '../components/Wrapper';
import IndexLayout from '../layouts';
import { colors } from '../styles/colors';
import {
  inner,
  outer,
  PostFeed,
  SiteDescription,
  SiteQuote,
  SiteHeader,
  SiteHeaderContent,
  SiteMain,
  SiteTitle,
  SiteNavMain,
  SiteArchiveHeader,
  ResponsiveHeaderBackground,
  SiteHeaderBackground,
} from '../styles/shared';
import { PageContext } from '../templates/post';

import { Footer } from '../components/Footer';

import { NoImage, PostFull, PostFullHeader, PostFullTitle } from '../templates/post';

import config from '../website-config';

const _ = require('lodash');

interface TagsTemplateProps {
  data: {
    allMarkdownRemark: {
      totalCount: number;
      edges: Array<{
        node: PageContext;
      }>;
    };
  };
}

const PageTemplate = css`
  .site-main {
    margin-top: 64px;
    padding-bottom: 4vw;
    background: #fff;
  }

  //@media (prefers-color-scheme: dark) {
    .site-main {
      /* background: var(--darkmode); */
      background: ${colors.darkmode};
    }
  //}
`;


const BList = styled.ul`
  list-style-type:none;
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  width: 100%;

  li {
    display: inline-block;
    width: 100%;
  }

  @media (max-width: 500px) {

    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
    text-align: center;
  }

`;

const TagsPage = props => {

  //console.log(props.data);
  let tags = _.uniq(
    _.flatten(
      props.data.allMarkdownRemark.edges.map(edge => {
        let slug = edge.node.fields.slug;
        let foo = _.get(edge, 'node.frontmatter', [])
        foo.slug = slug;
        return _.castArray(foo);
      }),
    ),
  );

  tags = _.map(
    tags, (tag) => {
      //console.log("TEXT TAG: ", tag);

      let name = tag.title;

      if(tag.author[0]) {
        name = name + ' by ' + tag.author[0];
      }

      return {
        type: tag.type, hidden: tag.hidden, link: tag.slug, name: name
      };
    }
  );

  tags = _.filter(tags, (tag) =>  {
    return tag.type === "text";
  });

  tags = _.filter(tags, (tag) =>  {
    return !tag.hidden;
  });

  let cardImage = `${config.siteUrl}${props.data.header.childImageSharp.fixed.src}`
  let { width, height } = props.data.header.childImageSharp.fixed;

  //console.log(tags);

  return (
    <IndexLayout>
      <Helmet>
        <title>{`Texts - ${config.title}`}</title>
          <meta name="description" content={'Texts'} />
          <meta property="og:site_name" content={config.title} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={`Texts - ${config.title}`} />
          <meta property="og:description" content={'Texts'} />
          <meta property="og:url" content={config.siteUrl + 'Texts'} />
          {config.facebook && <meta property="article:publisher" content={config.facebook} />}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={`Texts - ${config.title}`} />
          <meta name="twitter:url" content={config.siteUrl + 'Texts'} />
          <meta property="twitter:description" content={'Texts'} />
          {config.twitter && (
            <meta
              name="twitter:site"
              content={`@${config.twitter.split('https://twitter.com/')[1]}`}
            />
          )}
          <meta name="og:image" content={cardImage} />
          <meta name="twitter:image" content={cardImage} />
          <meta property="og:image:width" content={width.toString()} />
          <meta property="og:image:height" content={height.toString()} />
      </Helmet>
      <Wrapper css={PageTemplate}>
        <header className="site-archive-header no-image" css={[SiteHeader, SiteArchiveHeader]}>
          <div css={[outer, SiteNavMain]}>
            <div css={inner}>
              <SiteNav isHome={false} isPost={true} post={{title: 'Texts'}} />
            </div>
          </div>
        </header>
        <main id="site-main" css={[SiteMain, outer]}>
          <div css={[inner]}>
            <SiteHeaderContent className="post-full-header">
              <SiteTitle style={{textAlign:'center'}} className="post-full-title">Texts</SiteTitle>
              <SiteQuote style={{textAlign:'center', color: 'rgb(115, 138, 148)'}} className="site-quote">
                After you have chosen your words, they must be weaved together into a fine and delicate fabric. - Marcus Fabius Quintilianus
              </SiteQuote>
            </SiteHeaderContent>


            <p>
               Here is a collection of books and texts related to the topics explored by The Empty Robot.
               These texts are not necessarily reflective of our views, and many times will even contradict each other.
               They are here as a collection of influential, referenced, or interesting texts within the domains of philosophy, science, mindfulness, Buddhism, and more.
            </p>

            <div css={PostFeed} className="post-feed">
              <BList>
              {tags.map((tag) => (
                 <li key={tag.name} style={{}}>

                    <Link to={`${tag.link}`}>
                      {tag.name}
                    </Link>
                </li>
              ))}
              </BList>
            </div>
          </div>
        </main>

        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};

export const pageQuery = graphql`
  query {
    header: file(relativePath: { eq: "img/emptyrobot-background.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 1200, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
      allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: ASC }
        filter: { frontmatter: { draft: { ne: true }, type: {eq: "text"}, hidden: {eq: false} } }
      ) {
        edges {
          node {
            excerpt
            timeToRead
            frontmatter {
              title
              type
              tags
              date
              draft
              excerpt
              hidden
              author
            }
            fields {
              slug
            }
          }
        }
      }
    }
`;

export default TagsPage;
